import { createTheme } from '@material-ui/core';

// Set the theme dynamically based on an environment variable
export const myTheme = parseInt(process.env.REACT_APP_THEME) || 1;

export const getPrimaryColor = x => {
  x = parseInt(process.env.REACT_APP_THEME);

  switch (x) {
    case 1:
      return '#0062FF';
    case 2:
      return '#ED5617';
    case 3:
      return '#59C434';
    case 4:
      return 'rgba(238, 9, 121, 1)';
    case 5:
      return '#FFB801';
    case 6:
      return '#00FF00';
    case 7:
      return '#0019FF';
    case 8:
      return '#fbbe25';
    default:
      return '#0062FF';
  }
};

const theme = variant =>
  createTheme({
    palette: {
      primary: {
        main: getPrimaryColor()
      },
      secondary: {
        main: '#FFFFFF'
      },
      custom: {
        black: '#1A1A1E',
        white: '#FFFFFF',
        gray: '#292932',
        lightBlue: '#C7DDFF',
        blue: '#385682',
        lightGray: '#8E8B8B',
        red: '#FF6F6F',
        green: '#4BC666',
        darkBlack: '#15151A'
      }
    },
    typography: {
      fontFamily: 'Montserrat, Arial, sans-serif'
    }
  });

export default theme;
