import CryptoJS from 'crypto-js';
import { createTransform } from 'redux-persist';
import { getCurrentClient } from './general';

const secretKey = 'SECRET-' + getCurrentClient();

const encrypt = data => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return data;
  }
};

const decrypt = data => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Decryption error:', error);
    return data;
  }
};

const encryptTransform = createTransform(
  (inboundState, key) => encrypt(inboundState),
  (outboundState, key) => decrypt(outboundState)
);

export default encryptTransform;
