import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const Hijack = ({}) => {
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      window.localStorage.setItem('token', JSON.stringify(token));
      history.push('/app/dashboard');
    }
  }, [token, history]);

  return <div>Hijacking...</div>;
};

export default Hijack;
