import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loading from './components/Loading';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import App from './App';
import { ThemeProvider } from '@material-ui/core';
import theme, { myTheme } from './v2/theme';
import './global.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = configureStore();
const queryClient = new QueryClient();

const RenderApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loading />}>
            <ThemeProvider theme={theme(myTheme)}>
              <App />
            </ThemeProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};
ReactDOM.render(<RenderApp />, document.getElementById('root'));
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
