import thunk from 'redux-thunk';
import {
  settings,
  menu,
  auth,
  authUser,
  matches,
  userInfo,
  games,
  bets,
  sports,
  account,
  transactions
} from './reducers';
import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as models from '../models';
import encryptTransform from '../utils/crypto';

const middlewares = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['matches'],
  transforms: [encryptTransform]
};

const rootReducer = {
  settings,
  menu,
  auth,
  authUser,
  matches: persistReducer(persistConfig, matches),
  userInfo,
  games,
  bets,
  sports,
  account,
  transactions
};

export function configureStore() {
  const loading = createLoadingPlugin({});

  const store = init({
    redux: {
      reducers: rootReducer,
      middlewares
    },
    models,
    plugins: [loading, selectPlugin()]
  });

  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
}
